import FlashScreen from "./components/flashScreen";
// import Navigator from "./navigator";
import Form from "./screens/form";

const App = () => {
  return (
    <>
      <FlashScreen />
      <Form />
    </>
  );
};

export default App;
