import React, { useState, useEffect } from "react";

function TypingAnimation({ text, className }) {
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setTypedText((prevTypedText) => prevTypedText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 100); // Adjust the delay between characters

      return () => clearTimeout(timer);
    } else {
      const resetTimer = setTimeout(() => {
        setTypedText(typedText[0]);
        setCurrentIndex(1);
      }, 1000); // Adjust the delay before resetting

      return () => clearTimeout(resetTimer);
    }
  }, [currentIndex, text]);

  return <p className={className}>{typedText}</p>;
}

export default TypingAnimation;
