import React, { useState } from "react";
import TypingAnimation from "../components/typingTextAnimation";
import { Link } from "react-router-dom";
const Logo = require("../assets/images/png/logo.png");
const Form = () => {
  const [showPhotos, setShowPhotos] = useState(false);
  const HandleSubmitForm = (event) => {
    event.preventDefault();
    setShowPhotos(true);
  };
  if (showPhotos) {
    return (
      <div className="min-h-[100vh] w-full bg-[#ccac00] flex flex-col justify-center items-center gap-x-4">
        <div className="bg-black rounded-xl ">
          <img src={Logo} alt="logo" className="max-h-[14vh]" />
        </div>
        <div className="flex my-6 gap-x-4">
          <a
            href="https://photosonqr.ecapps.in/public-photos/"
            className="border border-black px-5 py-2 font-bold rounded-lg bg-black text-white"
          >
            Public Album
          </a>{" "}
          <a
            href="https://photosonqr.ecapps.in/private-photos/"
            className="border border-black px-5 py-2 font-bold rounded-lg bg-black text-white"
          >
            Protected Album
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-[100vh] w-full bg-[#ccac00] flex justify-center items-center">
      <div>
        <TypingAnimation
          text="Heartful Welcome by"
          className={"text-white text-center text-lg font-bold"}
        />
        <div className="bg-black rounded-xl">
          <img src={Logo} alt="logo" className="max-h-[14vh]" />
        </div>
        <form className="my-7" onSubmit={HandleSubmitForm}>
          <div className="flex flex-row my-3 items-center justify-between">
            <p className="text-lg font-bold">Name:</p>
            <input type="text" className="bg-white rounded" required />
          </div>
          <div className="flex flex-row my-3 items-center justify-between">
            <p className="text-lg font-bold">Mobile:</p>
            <input type="text" className="bg-white rounded" required />
          </div>
          <div className="flex flex-row my-3 items-center justify-between">
            <p className="text-lg font-bold">City:</p>
            <select
              id="cities"
              className="bg-white rounded max-w-[53vw]"
              required
            >
              <option value="" disabled selected>
                Select your city
              </option>
              <option value="Chennai">Chennai</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Madurai">Madurai</option>
              <option value="Tiruchirappalli">Tiruchirappalli</option>
              <option value="Salem">Salem</option>
              <option value="Tirunelveli">Tirunelveli</option>
              <option value="Tiruppur">Tiruppur</option>
              <option value="Erode">Erode</option>
              <option value="Vellore">Vellore</option>
              <option value="Thoothukudi">Thoothukudi</option>
              <option value="Dindigul">Dindigul</option>
              <option value="Thanjavur">Thanjavur</option>
              <option value="Ranipet">Ranipet</option>
              <option value="Sivakasi">Sivakasi</option>
              <option value="Karur">Karur</option>
              <option value="Udhagamandalam (Ooty)">
                Udhagamandalam (Ooty)
              </option>
              <option value="Hosur">Hosur</option>
              <option value="Nagercoil">Nagercoil</option>
              <option value="Kanchipuram">Kanchipuram</option>
              <option value="Kumarapalayam">Kumarapalayam</option>
            </select>
          </div>
          <div className="text-center py-5">
            <button className="border border-black px-5 py-2 font-bold rounded-lg bg-black text-white">
              Get Online Album
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Form;
