import React, { useEffect, useState } from "react";
const Logo = require("../assets/images/png/logo.png");

function FlashScreen() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000); // Set the duration for how long the flash screen should be visible

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${
        isVisible ? "flex" : "hidden"
      } justify-center items-center h-screen bg-black transition-opacity duration-500`}
    >
      <img src={Logo} alt="Logo" />
    </div>
  );
}

export default FlashScreen;
